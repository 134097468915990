@use '../../styles/colors';

$viewport-padding: 25px;

.viewport {
  right: 0;
  bottom: 0;
  gap: 10px;
  margin: 0;
  width: 390px;
  display: flex;
  outline: none;
  position: fixed;
  list-style: none;
  max-width: 100vw;
  z-index: 2147483647;
  flex-direction: column;
  padding: $viewport-padding;
}

.toast {
  padding: 10px;
  border-radius: 8px;

  &[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: hide 100ms ease-in;
  }
  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  flex: 1;
  font-size: 14px;
  margin-left: 12px;
  transform: translateY(1px);
}

.close {
  all: unset;
  padding: 5px;
  line-height: 0;
  cursor: pointer;
}

.success {
  background-color: colors.$green-400;

  .statusIcon {
    color: colors.$green;
  }
}

.error {
  background-color: colors.$red-400;

  .statusIcon {
    color: colors.$red;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + $viewport-padding));
  }
  to {
    transform: translateX(0);
  }
}
